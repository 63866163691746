import { Component, OnInit } from '@angular/core';
import { KeyReaderService } from '@services/key-reader/key-reader.service';
import { LoadingIndicatorService } from '@services/loading-indicator/loading-indicator.service';
import { IKeyReader } from '@utils/interfaces/key-reader.interface';
import { WebMessageAction } from "@utils/interfaces/web-messages/web-message-action.enum";
import { WebMessageFormat } from "@utils/interfaces/web-messages/web-message-format.interface";
import { VersionInformationMessage } from "@utils/interfaces/web-messages/versions-information.interface";
import { SessionStorageService } from '@services/session-storage/session-storage.service';

/**
 * Landingpage of the GSK application
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  // key reader information
  public keyReader: IKeyReader = {
    status: '',
    firmware: { currentVersion: '' },
    software: { currentVersion: '' },
  };
  //key readout numbers
  keyReadouts: any = {
    readout_count: 0,
  };

  /**
   * Creates an instance of KeyDataComponent.
   *
   * @param keyReaderService handles the key reader information
   * @param loadingIndicatorService contains the handling of the loading indicator
   * @param sessionStorage handles the session storage communication
   */
  constructor(
    private keyReaderService: KeyReaderService,
    public loadingIndicatorService: LoadingIndicatorService,
    private sessionStorage: SessionStorageService) {
  }

  ngOnInit(): void {
    // Get versions information
    this.requestToGetVersionInfoFromTrayApp();
    // Get key readouts number
    this.keyReaderService.getKeyReadoutCount().subscribe((data) => {
      this.keyReadouts = data;
    });
  }

  ngAfterViewInit(): void {
    // register web message handler for version information after component is initialized/created beacuse the firmware version information is not available before
    window.chrome?.webview.addEventListener('message', (event: MessageEvent) => {
      this.receiveVersionInfoMessageFromTrayApp(event);
    });
  }

  /**
   * Request the versions information from the tray app
   */
  public requestToGetVersionInfoFromTrayApp(): void {
    //register a web message handler to get versions information viw webmessages communication
    window.chrome?.webview.addEventListener('message', (event: MessageEvent) => this.receiveVersionInfoMessageFromTrayApp(event));
    const message: WebMessageFormat = {
      action: WebMessageAction.GetVersions,
      data: ""
    }
    window.chrome?.webview.postMessage(JSON.stringify(message));
  }

  /**
   * receive the versions information from the tray app via the webview webmessages
   *
   * @param event message event with the Webmessage action and data
   */
  public receiveVersionInfoMessageFromTrayApp(event: MessageEvent): void {
    const { action, data } = JSON.parse(event.data);
    // Handle only messages with 'SET_VERSIONS' action, ignore others
    if (action != WebMessageAction.SetVersions) {
      return
    }
    // Extract version information from event data which is a JSON string
    const versionInformation: VersionInformationMessage = JSON.parse(data);
    //set the key reader information
    this.keyReader = {
      status: this.keyReader.status,
      software: {
        currentVersion: versionInformation.SoftwareVersion,
      },
      firmware: {
        currentVersion: versionInformation.FirmwareVersion,
      },
    }
    this.sessionStorage.setItem("version",this.keyReader.software.currentVersion);

  }
}
