import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '@container/dashboard/dashboard.component';
import { KeyDataComponent } from '@container/key-data/key-data.component';
import { PdfKeyDataComponent } from '@container/pdf/components/pdf-key-data/pdf-key-data.component';
import { PdfComponent } from '@container/pdf/pdf.component';
import { EditGdprComponent } from '@container/edit-gdpr/edit-gdpr.component';
import { AuthServiceGuard } from '@services/auth-service/auth-service.guard';
import { HistoricalReadoutComponent } from './container/historical-readout/historical-readout.component';

// different routes
const routes: Routes = [{
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full'
  },
  {
    path: 'data',
    component: KeyDataComponent,
    pathMatch: 'full'
  },
  {
    path: 'historical-readout',
    component: HistoricalReadoutComponent,
    pathMatch: 'full'
  },
  {
    path: 'edit',
    component: EditGdprComponent,
    pathMatch: 'full',
    canActivate:[AuthServiceGuard]
  },
  {
    path: 'pdf',
    outlet: 'pdf',
    component: PdfComponent,
    children: [
      { path: 'data', component: PdfKeyDataComponent }
    ]
  }
];

/**
 * routing of the application
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
