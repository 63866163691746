import { Component } from '@angular/core';
import { LogoutService } from '@services/logout/logout.service';

/**
 * This button component is used to logout the current user.
 */
@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss']
})
export class LogoutButtonComponent {

  /**
   * Creates an instance of LogoutButtonComponent.
   *
   * @param logoutService handles the logout of the user
   */
  constructor(public logoutService: LogoutService) { }

  /**
   * Call the logout service to logout the user
   */
  logoutUser(): void {
    this.logoutService.logoutUser();
  }
}
