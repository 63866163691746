import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * This service handles the key reader information
 */
@Injectable({
  providedIn: 'root',
})
export class KeyReaderService {
  /**
   * Creates an instance of KeyReaderService.
   *
   * @param http methods to perform HTTP requests
   */
  constructor(private http: HttpClient) {
    this.getKeyReadoutCount();
  }

  /**
   * Creats the http header information for the request options
   *
   * @returns request options
   */
  private getRequestOptions(): { headers: HttpHeaders } {
    let headerOptions = {
      'Content-Type': 'application/json',
    };
    let headers = new HttpHeaders(headerOptions);
    return {
      headers: headers,
    };
  }

  /**
   * Gets the number of the key readouts
   *
   * @returns key reader count
   */
  getKeyReadoutCount(): Observable<Object> {
    const options = this.getRequestOptions();
    const url = 'da/keydata/count';
    //const url = window.location.pathname + '/../assets/data/key-readouts.json';
    return this.http.post(url, null, options);
  }
}
