<app-icon-card [icon]="iconPath" [addInfoText]="addInfoText" *ngIf="displayData$ | async">
  <groupui-grid-row>

    <!-- active warning message -->
    <groupui-grid-col xxl="6" xl="12" l="12" l="12" m="12" s="12" class="unsetPaddingRight">
      <!-- title -->
      <groupui-grid-row>
        <groupui-grid-col class="value fieldName">{{mapService.mapFieldIdToPath(activeWarnings.fieldId,
          translationPath) | translate | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- status and message -->
      <groupui-grid-row *ngFor="let activeWarningMessage of activeWarnings?.warningMessages">
        <groupui-grid-col l="1" m="1" s="1" class="fieldName">
          <img *ngIf="activeWarningMessage.iconBase64 && !activeWarningMessage.iconBase64.includes('ICON_NOT_FOUND')"
               [src]="activeWarningMessage.iconBase64 | sanitizer" alt="">
        </groupui-grid-col>
        <groupui-grid-col l="11" m="11" s="11" class="value textWrapFloatRight">
          {{ activeWarningMessage?.message | placeholder }}
        </groupui-grid-col>
      </groupui-grid-row>

      <!-- whitespace -->
      <groupui-grid-row class="hideXXL">
        &nbsp;
      </groupui-grid-row>
    </groupui-grid-col>

    <!-- historical warning messages -->
    <groupui-grid-col xxl="6" xl="12" l="12" m="12" s="12" class="unsetPaddingRight">
      <!-- title -->
      <groupui-grid-row>
        <groupui-grid-col class="value fieldName">
          {{mapService.mapFieldIdToPath(historicalWarnings.fieldId, translationPath) | translate | uppercase |
          placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- status and message -->
      <groupui-grid-row *ngFor="let historicalWarningMessage of historicalWarnings?.warningMessages">
        <groupui-grid-col l="1" m="1" s="1" class="fieldName">
          <img
            *ngIf="historicalWarningMessage.iconBase64 && !historicalWarningMessage.iconBase64.includes('ICON_NOT_FOUND')"
            [src]="historicalWarningMessage.iconBase64 | sanitizer" alt="">
        </groupui-grid-col>
        <groupui-grid-col l="11" m="11" s="11" class="value textWrapFloatRight">
          {{ historicalWarningMessage?.message | placeholder }} | {{historicalWarningMessage?.timestamp |
          date:'dd-MM-YY' | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
    </groupui-grid-col>

  </groupui-grid-row>
</app-icon-card>
