<app-icon-card [icon]="iconPath" [addInfoText]="addInfoText" *ngIf="displayData$ | async">
  <groupui-grid-row class="content-container">
    <!-- first col (wltp) -->
    <groupui-grid-col xl="6" l="12" m="12" s="12" class="unsetPaddingRight">
      <!-- title -->
      <groupui-grid-row>
        <groupui-grid-col class="value fieldName textWrapFloatRight">
          {{mapService.mapFieldIdToPath(emissionData?.wltpData?.fieldId, translationPath) | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- low -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.wltpData?.low?.fieldId, translationPath)"
        [value]="castNumberToString(emissionData?.wltpData?.low?.value)"
        [unit]="emissionData?.wltpData?.low?.unit">
      </app-description-value-cell>
      <!-- medium -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.wltpData?.medium?.fieldId, translationPath)"
        [value]="castNumberToString(emissionData?.wltpData?.medium?.value)"
        [unit]="emissionData?.wltpData?.medium?.unit">
      </app-description-value-cell>
      <!-- high -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.wltpData?.high?.fieldId, translationPath)"
        [value]="castNumberToString(emissionData?.wltpData?.high?.value)"
        [unit]="emissionData?.wltpData?.high?.unit">
      </app-description-value-cell>
      <!-- extra high -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.wltpData?.extraHigh?.fieldId, translationPath)"
        [value]="castNumberToString(emissionData?.wltpData?.extraHigh?.value)"
        [unit]="emissionData?.wltpData?.extraHigh?.unit">
      </app-description-value-cell>
      <!-- combined -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.wltpData?.combined?.fieldId, translationPath)"
        [value]="castNumberToString(emissionData?.wltpData?.combined?.value)"
        [unit]="emissionData?.wltpData?.combined?.unit">
      </app-description-value-cell>
      <!-- co2 emission combined -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.wltpData?.emissionCombined?.fieldId, translationPath)"
        [value]="castNumberToString(emissionData?.wltpData?.emissionCombined?.value)"
        [unit]="emissionData?.wltpData?.emissionCombined?.unit">
      </app-description-value-cell>
      <!-- whitespace -->
      <groupui-grid-row class="hideXL">
        &nbsp;
      </groupui-grid-row>
    </groupui-grid-col>

    <!-- second col (nedc) -->
    <groupui-grid-col xl="6" l="12" m="12" s="12" class="unsetPaddingRight">
      <!-- title -->
      <groupui-grid-row>
        <groupui-grid-col class="value fieldName textWrapFloatRight">
          {{mapService.mapFieldIdToPath(emissionData?.nedcData?.fieldId, translationPath) | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- Inner city -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.nedcData?.innerCity?.fieldId, translationPath)"
        [value]="emissionData?.nedcData?.innerCity?.value" [unit]="emissionData?.nedcData?.innerCity?.unit">
      </app-description-value-cell>
      <!-- Out of town -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.nedcData?.outOfTown?.fieldId, translationPath)"
        [value]="emissionData?.nedcData?.outOfTown?.value" [unit]="emissionData?.nedcData?.outOfTown?.unit">
      </app-description-value-cell>
      <!-- combined -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.nedcData?.combined?.fieldId, translationPath)"
        [value]="emissionData?.nedcData?.combined?.value" [unit]="emissionData?.nedcData?.combined?.unit">
      </app-description-value-cell>
      <!-- co2 emission combined -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.nedcData?.emissionCombined?.fieldId, translationPath)"
        [value]="emissionData?.nedcData?.emissionCombined?.value"
        [unit]="emissionData?.nedcData?.emissionCombined?.unit">
      </app-description-value-cell>
      <!-- Energy efficiency class -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(emissionData?.nedcData?.energyEfficiency?.fieldId, translationPath)"
        [value]="emissionData?.nedcData?.energyEfficiency?.value"
        [unit]="emissionData?.nedcData?.energyEfficiency?.unit">
      </app-description-value-cell>
      <!-- whitespace -->
      <groupui-grid-row class="hideS hideM hideL showXL">
        &nbsp;
      </groupui-grid-row>
    </groupui-grid-col>
  </groupui-grid-row>
</app-icon-card>
