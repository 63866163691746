<app-icon-card [icon]="iconPath" [addInfoText]="addInfoText" *ngIf="displayData$ | async">
  <groupui-grid-row class="content-container">
    <!-- first col (charge levels) -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <!-- high voltage battery -->
      <groupui-grid-row>
        <groupui-grid-col l="11" m="11" s="11" class="fieldName textWrapFloatLeft">
          {{mapService.mapFieldIdToPath(batteryChargeLevel.batteryLevelHV.fieldId, batteryChargeLevelTranslationPath) | placeholder }}
        </groupui-grid-col>
        <groupui-grid-col l="1" m="1" s="1" class="value">
          {{ batteryChargeLevel.batteryLevelHV.value | placeholder }} {{batteryChargeLevel.batteryLevelHV.unit}}
        </groupui-grid-col>
      </groupui-grid-row>

      <!-- 12V battery -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(batteryChargeLevel.batteryLevel.fieldId, batteryChargeLevelTranslationPath)"
        [value]="batteryChargeLevel.batteryLevel.value" [unit]="batteryChargeLevel.batteryLevel.unit">
      </app-description-value-cell>
    </groupui-grid-col>

    <!-- second col (measurement successful) -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <!-- measurement successful -->
      <groupui-grid-row>
        <groupui-grid-col l="11" m="11" s="11" class="fieldName textWrapFloatLeft">{{mapService.mapFieldIdToPath(electricVehicleInformation.capacity.fieldId, batteryChargeLevelTranslationPath) | placeholder }}</groupui-grid-col>
        <groupui-grid-col l="1" m="1" s="1" class="value">{{ electricVehicleInformation.capacity.value | placeholder | translate }}</groupui-grid-col>
      </groupui-grid-row>
      <!-- whitespace -->
      <groupui-grid-row class="hideS showL">
        &nbsp;
      </groupui-grid-row>
    </groupui-grid-col>

  </groupui-grid-row>
</app-icon-card>
