import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfComponent } from './pdf.component';
import { PdfKeyDataComponent } from './components/pdf-key-data/pdf-key-data.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations: [PdfComponent, PdfKeyDataComponent],
  imports: [
    CommonModule,
    GroupUiModule,
    AppRoutingModule,
    BrowserModule
  ]
})
export class PdfModule { }
