/**
 * Logos belonging to the brand code
 */
export enum BrandCodeLogoEnum {
  A = 'audi.png',
  C = 'skoda.png',
  S = 'seat.png',
  V = 'vw.png',
  N = 'vw.png'
}
