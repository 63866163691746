/* eslint-disable guard-for-in */
import {Injectable} from '@angular/core';

/**
 * Checks whether the incoming data are empty
 */
@Injectable({
  providedIn: 'root'
})
export class MissingDataService {

  /**
   * Checks any nested object or array if the individual elements are filled with any object
   * that contains a key named "value" that contains a valid value. Another case is the check
   * for the warning lights. In that case the check inside an array if contains an object that
   * is a valid warning light.
   *
   * @param input object to be controlled
   * @param dataExist is a value available
   * @returns is a value available
   */
  public valueAvailableCheck(input: any, dataExist: boolean): boolean {
    // early out
    if (dataExist) {
      return true;
    }
    // check if it is an array and pass it through
    if (Array.isArray(input)) {
      if (this.isValidWarningLightsArray(input)) return true;
      input.forEach((val: any) => {
        dataExist = this.valueAvailableCheck(val, dataExist);
      });
      // check if it is an object and pass it through
    } else if (typeof input === 'object' && input != null) {
      if ('value' in input) {
        const value = input.value;
        if (this.isValidValue(value)) {
          return true;
        }
      }
      for (const key in input) {
        dataExist = this.valueAvailableCheck(input[key], dataExist);
      }
    }
    return dataExist;
  }

  /**
   * Checks if the object is a valid warning light (is instance of IWarningMessage.
   *
   * @param value object
   * @returns {boolean} - True if value is a valid warning light, false otherwise.
   */
  public isValidWarningLightsArray(value: any[]): boolean {
    if (value.length) {
      const firstElement = value[0];
      return this.isValidWarningLightElement(firstElement);
    }
    return false;
  }

  /**
   * Checks if the object is a valid warning light (is instance of IWarningMessage.
   *
   * @param value object
   * @returns {boolean} - True if value is a valid warning light, false otherwise.
   */
  isValidWarningLightElement(value: any): boolean {
    if (value !== undefined && value !== null)
      return 'iconBase64' in value && 'message' in value && 'source' in value;
    return false;
  }

  /**
   * Checks if the incoming key data set (vehicle condition, vehicle diagnostic and oil norm data) is empty.
   *
   * @param data key data information
   * @param dataGroup array of string keys that are expected to be part of data
   * @returns {boolean} - True if data is empty, false otherwise.
   */
  public isDataEmpty(data: any, dataGroup: Array<string>): boolean {
    let dataExists = false;
    for (const key of dataGroup) {
      if (key in data) {
        const valueAvailable = this.valueAvailableCheck(data[key], dataExists);
        if (valueAvailable) {
          dataExists = valueAvailable;
          break;
        }
      }
    }
    return !dataExists;
  }

  /**
   * Checks if the given value is valid based on the following conditions:
   * - Not an empty string
   * - Not undefined
   * - Not null
   * - Not 0
   *
   * @param {any} value - The value to be checked.
   * @returns {boolean} - True if the value is valid, false otherwise.
   */
  isValidValue(value: any): boolean {
    return value !== '' && value !== undefined && value !== null && value != 0;
  }
}
