import { Component, Input } from '@angular/core';

/**
 * This Card displays a icon of the left side and additional information.
 */
@Component({
  selector: 'app-icon-card',
  templateUrl: './icon-card.component.html',
  styleUrls: ['./icon-card.component.scss']
})
export class IconCardComponent {
  // name of the card icon
  @Input() icon!: string;
  // additional information which should displayed in tags
  @Input() addInfoText?: Array<string> = [];
}
