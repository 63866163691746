<!-- ids are needed to find the print view in the dom using the download service -->
<!-- inline style is necessary for the pdf creator  -->
<div id="pdf-header">
  <table data-pdfmake="{'widths':['auto','*','auto'], 'layout': 'noBorders'}">
    <caption>pdf header</caption>
    <tr>
      <th colspan="3" style="background-color: white"></th>
    </tr>
    <tr>
      <td>
        <img id="pdf-header-gskLogo" style="height: 38px; width: 150px; margin-left: 45px; margin-top: 15px; margin-bottom: 20px" src="" alt="GSK Logo">
      </td>
      <td>
      </td>
      <td>
        <img id="pdf-header-internalBanner" style="height: 24px; width: 161px; margin-top: 15px; margin-right: 45px;"
          src="" alt="Image Source">
      </td>
    </tr>
  </table>
</div>

<!-- ids are needed to find the print view in the dom using the download service -->
<!-- inline style is necessary for the pdf creator  -->
<div id="pdf-content">
  <!-- Print/Download content -->
  <table data-pdfmake="{'widths':['*'], 'layout': 'noBorders'}">
    <caption>Key data pdf view content</caption>
    <tr>
      <th></th>
    </tr>
    <tr>
      <td>
        <router-outlet></router-outlet>
      </td>
    </tr>
  </table>
</div>

<!-- ids are needed to find the print view in the dom using the download service -->
<!-- inline style is necessary for the pdf creator  -->
<div id="pdf-footer">
  <table data-pdfmake="{'widths':['*', 'auto', '*'], 'layout': 'noBorders', 'alignment': 'right'}">
    <caption>Key data pdf view footer</caption>
    <tr>
      <th colspan="3" style="background-color: white"></th>
    </tr>
    <tr>
      <td></td>
      <td>
        <img id="pdf-footer-vwagLogo" style="height: 50px; width: 195px; margin-top: 5px; margin-bottom: 5px" src=""
          alt="GSK Logo">
      </td>
      <td></td>
    </tr>
    <tr></tr>
  </table>
</div>
