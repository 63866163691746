import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { VehicledataService } from '@services/vehicledata/vehicledata.service';
import { LoadingIndicatorService } from '@services/loading-indicator/loading-indicator.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { AuthServiceGuard } from '@services/auth-service/auth-service.guard';

/**
 * This component includes the app shell and the integration of routing
 */
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  // ids of the footer items, used to call the right element from translation json
  public footerItems: Array<string> = [ 'imprint', 'terms-of-use', 'privacy-policy', 'third-party-licenses'];
  // indicates if admin privileges are available
  importerAdminFlag: any;

  /**
   * Creates an instance of the ShellComponent
   *
   * @param loadingIndicatorService contains the handling of the loading indicatory
   * @param router provite navigation among the views
   * @param sessionStorage handles the session storage communication
   * @param vehicledataService handles the key data information (necessary here to initialise the service)
   * @param authServiceGuard checks if the user has admin access
   */
  constructor(
    public loadingIndicatorService: LoadingIndicatorService,
    public vehicledataService: VehicledataService,
    public router: Router,
    public sessionStorage: SessionStorageService,
    public authServiceGuard: AuthServiceGuard
  ) {}

  /**
   * Check the admin privileges and if the loading indicator for navigation should be dispalyed
   */
  async ngOnInit(): Promise<void> {
    this.importerAdminFlag = await this.authServiceGuard.checkAdminPrivileges();
    // start and end the loading indicator during navigation (is ended in the loaded component)
    // this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationStart) {
    //     this.loadingIndicatorService.show();
    //   } else if (event instanceof NavigationEnd) {
    //     this.loadingIndicatorService.hide();
    //   }
    // });
    this.router.events.subscribe({
      next:(data) => {
        if(data instanceof  NavigationStart) {
          this.loadingIndicatorService.show();
        }
        else if (data instanceof  NavigationEnd) {
          this.loadingIndicatorService.hide();
        }
      }
    })
  }

  /**
   * Navigating to the different pages (Dashboard, Key Data, Historical Readouts, etc.).
   * As the use of "href" is not possible (it triggers a pagereload and empties the ReplaySubject
   * in the VehicleDataService for the Key Data page).
   *
   * @param path naviation path
   */
  navigateTo(path: string): void {
    this.router.navigate([path]);
  }
}
