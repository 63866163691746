import { Component, Input } from '@angular/core';
import { IVersion } from '@utils/interfaces/version.interface';

/**
 * This Card displays the Version status of the current software or firmware and if they are up to date or need to be updated.
 */
@Component({
  selector: 'app-version-card',
  templateUrl: './version-card.component.html',
  styleUrls: ['./version-card.component.scss']
})
export class VersionCardComponent {
  // Version status of the current software or firmware
  @Input() set version(version: IVersion) {
    this.currentVersion = version.currentVersion;
  };
  // which type (firmware or software) has this card
  @Input() versionType!: 'firmware' | 'software';
  // current firmware or software version
  currentVersion = '';
}
