/**
 * Recurring default values of the whole GSK FE
 */
export class DefaultSettingsConstants {
  // local storage variable for the used language
  public readonly localStorageLanguageVariable = 'language';
  // default language of GSK - british english
  public readonly defaultLanguage = 'GB';
  // local storage variable for the market code
  public readonly localStorageMarketVariable = 'marketCode';
}
