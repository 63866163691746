import { Component, Input } from '@angular/core';

/**
 * This Card displays the number of the key readouts.
 */
@Component({
  selector: 'app-historical-readouts-card',
  templateUrl: './historical-readouts-card.component.html',
  styleUrls: ['./historical-readouts-card.component.scss'],
})
export class HistoricalReadoutsCardComponent {
  // incomming number of the key readings
  @Input() set readoutNumber(readoutNumber: number) {
    this.currentReadoutNumber = readoutNumber;
  }
  // displayed number of the key readings
  currentReadoutNumber: any;
}
