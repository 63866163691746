import { Component, Input } from '@angular/core';
import { IVersion } from '@utils/interfaces/version.interface';

/**
 * Component of the firmware status
 */
@Component({
  selector: 'app-firmware-version',
  templateUrl: './firmware-version.component.html'
})
export class FirmwareVersionComponent {
  // Current status of the firmware version
  @Input() set version(version: IVersion) {
    this.currentVersion = version;
  };

  // currently selected version
  currentVersion: IVersion = { currentVersion: '' };
  // What version type is it (software or firmware)
  readonly versionType = 'firmware';
}
