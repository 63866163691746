<div class="container">
  <!-- app header -->
  <app-header></app-header>
  <!-- sub header/navigation -->
  <groupui-top-navigation>
    <groupui-top-navigation-item (click)="navigateTo('/')" [active]="router.url === '/dashboard'">{{
      'shell.header.dashboard-title' | translate }}</groupui-top-navigation-item>
    <groupui-top-navigation-item (click)="navigateTo('/data')"
      [disabled]="sessionStorage.getItem('keyDataAvailable') === ''"
      [active]="router.url === '/data'">{{'shell.header.key-data-title' | translate }}</groupui-top-navigation-item>
    <groupui-top-navigation-item (click)="navigateTo('/historical-readout')"
      [active]="router.url === '/historical-readout'">{{ 'shell.header.historical-readout-title' | translate
      }}</groupui-top-navigation-item>
    <groupui-top-navigation-item (click)="navigateTo('/edit')" [active]="router.url === '/edit'"
      *ngIf="importerAdminFlag" id="edit-gdpr">{{ 'shell.header.edit-gdpr-title' | translate
      }}</groupui-top-navigation-item>
  </groupui-top-navigation>

  <div class="content">
    <ng-content></ng-content>
    <!-- loading spinner -->
    <div class="loading-indicator" [class.shown]=" loadingIndicatorService.loading$ | async ">
      <div id="loading-indicator">
        <img src="assets/icons/shell/car-key-48.svg" alt="gsk logo" />
      </div>
    </div>
  </div>

  <app-footer [footerItems]="footerItems"></app-footer>

</div>
