/**
 * names of the possible message actions
 */
export enum WebMessageAction {
  GetLanguage = "GET_LANGUAGE",
  SetLanguage = "SET_LANGUAGE",
  SendKeyDataStart = "SEND_KEY_DATA_START",
  SendKeyDataEnd = "SEND_KEY_DATA_END",
  GetVersions = "GET_VERSIONS",
  SetVersions = "SET_VERSIONS",
  GET_UUID = "GET_UUID",
  SET_UUID = "SET_UUID"
}
