import { Component } from '@angular/core';

/**
 * Print view for the KeyDataComponent
 */
@Component({
  selector: 'app-pdf-key-data',
  templateUrl: './pdf-key-data.component.html'
})
export class PdfKeyDataComponent {
}
