<!-- id is needed to close the dropdown -->
<details (keydown)="onKeyDown($event)" id="language-dropdown">
  <summary>
    <groupui-tooltip position="left">
      <groupui-button size="s" inverted="true" size="s" variant="tertiary">
        <div class="language-button">
          <img src="assets/icons/shell/flag-24.svg" alt="" />
          {{currentLanguage.countryCode}}
        </div>
      </groupui-button>

      <div slot="tooltip" variant="primary">{{currentLanguage.displayCountry}} - {{currentLanguage.countryCode}} </div>
    </groupui-tooltip>
  </summary>

  <div class="dropdown-content">
    <ul id="dropdown-list">
      <li *ngFor="let language of languages; index as i"
        [ngClass]="{'selected' : currentLanguage.countryCode == language.countryCode, 'hover-dropdown-item' :  i == hoverElement}"
        (click)="changeLanguage(language.countryCode, false)" id="{{'language-dropdown-'+i}}">
        <img src="{{'assets/icons/flags/' + language.countryCode + '.svg'}}" alt="" />
        {{language.displayCountry}}
      </li>
    </ul>
  </div>
</details>
