import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * disable Angular’s built-in sanitization
 */
@Pipe({
  name: 'sanitizer'
})
export class SanitizerPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any): any {
    if (typeof value !== 'string' || value === null || value === undefined) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustUrl(value);
  }
}
