import { Component, Input } from '@angular/core';

/**
 * Component of the key readouts count
 */
@Component({
  selector: 'app-key-readouts',
  templateUrl: './key-readouts.component.html',
})
export class KeyReadoutsComponent {
  // incomming number of the key readings
  @Input() set readoutNumber(readoutNumber: number) {
    this.currentReadoutNumber = readoutNumber;
  };
  // displayed number of the key readings
  public currentReadoutNumber: number;

}
