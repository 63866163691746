<app-icon-card [icon]="iconPath" [addInfoText]="addInfoText" *ngIf="displayData$ | async">
  <div *ngFor="let troubleCode of troubleCodesData" class="dtc">
    <!-- DTC header with the control unit and diagnostic address -->
    <groupui-grid-row class="card-header">
      <groupui-grid-col l="6" m="6" s="12" class="unsetPaddingRight">
        <app-description-value-cell
          [fieldName]="mapService.mapFieldIdToPath(troubleCode.controlUnit.fieldId, translationPath)"
          [value]="troubleCode.controlUnit.value">
        </app-description-value-cell>
      </groupui-grid-col>
      <groupui-grid-col l="6" m="6" s="12" class="unsetPaddingRight">
        <app-description-value-cell
          [fieldName]="mapService.mapFieldIdToPath(troubleCode.address.fieldId, translationPath)"
          [value]="troubleCode.address.value">
        </app-description-value-cell>
      </groupui-grid-col>
    </groupui-grid-row>
    <hr>
    <!-- trouble codes associated with the header  -->
    <div *ngFor="let code of troubleCode.codes" class="card-body">
      <groupui-grid-row>
        <groupui-grid-col l="12" m="12" s="12">
          <app-description-value-cell [fieldName]="mapService.mapFieldIdToPath(code.event.fieldId, translationPath)"
                                      [value]="code.event.value">
          </app-description-value-cell>
        </groupui-grid-col>
      </groupui-grid-row>

      <groupui-grid-row>
        <groupui-grid-col l="6" m="6" s="12">
          <app-description-value-cell
            [fieldName]="mapService.mapFieldIdToPath(code.displayTroubleCode.fieldId, translationPath)"
            [value]="code.displayTroubleCode.value">
          </app-description-value-cell>
        </groupui-grid-col>
        <groupui-grid-col l="6" m="6" s="12">
          <app-description-value-cell
            [fieldName]="mapService.mapFieldIdToPath(code.eventStatus.fieldId, translationPath)"
            [value]="code.eventStatus.value">
          </app-description-value-cell>
        </groupui-grid-col>
      </groupui-grid-row>


      <groupui-grid-row>
        <groupui-grid-col l="6" m="6" s="12">
          <groupui-grid-row>
            <groupui-grid-col l="6" m="6" s="6" class="fieldName">{{mapService.mapFieldIdToPath(code.timestamp.fieldId,
              translationPath) | placeholder }}
            </groupui-grid-col>
            <groupui-grid-col l="6" m="6" s="6" class="value">{{ mapService.mapDateOrPlaceholder(code.timestamp.value) |
              placeholder}}
            </groupui-grid-col>
          </groupui-grid-row>

        </groupui-grid-col>
        <groupui-grid-col l="6" m="6" s="12">
          <app-description-value-cell [fieldName]="mapService.mapFieldIdToPath(code.mileage.fieldId, translationPath)"
                                      [value]="code.mileage.value" [unit]="code.mileage.unit">
          </app-description-value-cell>
        </groupui-grid-col>
      </groupui-grid-row>
    </div>
    <hr class="seperator">
  </div>
</app-icon-card>
