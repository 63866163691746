<app-icon-card [icon]="iconPath" [addInfoText]="addInfoText" *ngIf="displayData$ | async">
  <groupui-grid-row>
    <!-- first col (oil quantity/level, fossil fuel) -->
    <groupui-grid-col xl="6" l="6" m="12" s="12" class="unsetPaddingRight">
      <!-- oil quantity -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(fluidLevels.oilAmount.fieldId, translationPath)"
        [value]="fluidLevels.oilAmount.value" [unit]="fluidLevels.oilAmount.unit">
      </app-description-value-cell>
      <!-- oil level -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(fluidLevels.oilLevel.fieldId, translationPath)"
        [value]="fluidLevels.oilLevel.value" [unit]="fluidLevels.oilLevel.unit">
      </app-description-value-cell>
    </groupui-grid-col>

    <!-- second col (oil quantity to max, fuel tank level and production date) -->
    <groupui-grid-col xl="6" l="6" m="12" s="12" class="unsetPaddingRight">
      <!-- oil quantity to max -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(fluidLevels.oilDifference.fieldId, translationPath)"
        [value]="fluidLevels.oilDifference.value" [unit]="fluidLevels.oilDifference.unit">
      </app-description-value-cell>
      <!-- fuel tank level -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(fluidLevels.fuelLevel.fieldId, translationPath)"
        [value]="fluidLevels.fuelLevel.value" [unit]="fluidLevels.fuelLevel.unit">
      </app-description-value-cell>
    </groupui-grid-col>

  </groupui-grid-row>

  <!-- whitespace -->
  <groupui-grid-row>
    &nbsp;
  </groupui-grid-row>

  <!-- oil norm data -->
  <groupui-grid-row style="display: block;">
    <groupui-grid-row>
      <groupui-grid-col class="fieldName" xl="6" l="6" m="6" s="6">
        {{mapService.mapFieldIdToPath(oilNorm.fixedOilChange.fieldId, translationPath) | placeholder}}
        <a class="hideSmall">
          | {{mapService.mapFieldIdToPath(oilNorm.capacityFixedOilChange.fieldId, translationPath) |
          placeholder}}
        </a>
      </groupui-grid-col>
      <groupui-grid-col class="value unsetPaddingRight" xl="6" l="6" m="6" s="6">
        {{oilNorm.fixedOilChange.value | placeholder}}
        <a class="hideSmall">
          &nbsp; | {{oilNorm.capacityFixedOilChange.value | placeholder}}
          {{oilNorm.capacityFixedOilChange.unit }}
        </a>
      </groupui-grid-col>
    </groupui-grid-row>

    <groupui-grid-row class="showSmall">
      <groupui-grid-col xl="12" l="12" m="12" s="12" class="unsetPaddingRight">
        <app-description-value-cell
          [fieldName]="mapService.mapFieldIdToPath(oilNorm.capacityFixedOilChange.fieldId, translationPath)"
          [value]="oilNorm.capacityFixedOilChange.value" [unit]="oilNorm.capacityFixedOilChange.unit">
        </app-description-value-cell>
      </groupui-grid-col>
    </groupui-grid-row>

    <groupui-grid-row>
      <groupui-grid-col class="fieldName " xl="6" l="6" m="6" s="6">
        {{ mapService.mapFieldIdToPath(oilNorm.flexibleOilChange.fieldId, translationPath) |
        placeholder}}
        <a class="hideSmall">
          | {{ mapService.mapFieldIdToPath(oilNorm.capacityFlexibleOilChange.fieldId, translationPath) |
          placeholder}}
        </a>
      </groupui-grid-col>
      <groupui-grid-col class="value unsetPaddingRight" xl="6" l="6" m="6" s="6">
        {{oilNorm.flexibleOilChange.value | placeholder}}
        <a class="hideSmall">
          &nbsp; | {{oilNorm.capacityFlexibleOilChange.value | placeholder}}
          {{oilNorm.capacityFlexibleOilChange.unit }}
        </a>

      </groupui-grid-col>
    </groupui-grid-row>


    <groupui-grid-row class="showSmall">
      <groupui-grid-col xl="12" l="12" m="12" s="12" class="unsetPaddingRight">
        <app-description-value-cell
          [fieldName]="mapService.mapFieldIdToPath(oilNorm.capacityFlexibleOilChange.fieldId, translationPath)"
          [value]="oilNorm.capacityFlexibleOilChange.value" [unit]="oilNorm.capacityFlexibleOilChange.unit">
        </app-description-value-cell>
      </groupui-grid-col>
    </groupui-grid-row>
  </groupui-grid-row>

  <!-- whitespace -->
  <groupui-grid-row>
    &nbsp;
  </groupui-grid-row>


  <groupui-grid-row>
    <groupui-grid-col xl="6" l="6" m="12" s="12" class="unsetPaddingRight">
      <!-- fuel range, fossil fuel -->
      <groupui-grid-row>
        <groupui-grid-col l="11" m="11" s="11" class="fieldName textWrapFloatLeft">
          {{mapService.mapFieldIdToPath(remainingRangesOfDrives.primaryRemainingRange.fieldId, translationPath) |
          placeholder }}
        </groupui-grid-col>
        <groupui-grid-col l="1" m="1" s="1" class="value">
          {{ remainingRangesOfDrives.primaryRemainingRange.value | placeholder }}
          {{ remainingRangesOfDrives.primaryRemainingRange.unit }}
        </groupui-grid-col>
      </groupui-grid-row>


      <!-- fuel range, LPG -->
      <groupui-grid-row>
        <groupui-grid-col l="11" m="11" s="11" class="fieldName textWrapFloatLeft">
          {{mapService.mapFieldIdToPath(remainingRangesOfDrives.secondaryRemainingRange.fieldId, translationPath) |
          placeholder }}
        </groupui-grid-col>
        <groupui-grid-col l="1" m="1" s="1" class="value">
          {{ remainingRangesOfDrives.secondaryRemainingRange.value | placeholder }} {{
          remainingRangesOfDrives.secondaryRemainingRange.unit }}
        </groupui-grid-col>
      </groupui-grid-row>
    </groupui-grid-col>

    <!-- total remaining range -->
    <groupui-grid-col xl="6" l="6" m="12" s="12" class="unsetPaddingRight">
      <groupui-grid-row>
        <groupui-grid-col l="11" m="11" s="11" class="fieldName textWrapFloatLeft">
          {{mapService.mapFieldIdToPath(remainingRangesOfDrives.remainingRange.fieldId, translationPath) | placeholder
          }}
        </groupui-grid-col>
        <groupui-grid-col l="1" m="1" s="1" class="value">
          {{ remainingRangesOfDrives.remainingRange.value | placeholder }} {{
          remainingRangesOfDrives.remainingRange.unit }}
        </groupui-grid-col>
      </groupui-grid-row>

      <!-- ad blue -->
      <groupui-grid-row>
        <groupui-grid-col l="11" m="11" s="11" class="fieldName textWrapFloatLeft">
          {{mapService.mapFieldIdToPath(adBlue.fieldId, translationPath) | placeholder }}
        </groupui-grid-col>
        <groupui-grid-col l="1" m="1" s="1" class="value">
          {{ adBlue.value | placeholder }} {{ adBlue.unit }}
        </groupui-grid-col>
      </groupui-grid-row>

    </groupui-grid-col>
  </groupui-grid-row>
</app-icon-card>
