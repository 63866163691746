<groupui-header>
  <groupui-grid-row flexible>
    <!-- left side of the header -->
    <groupui-grid-col>
      <a href="#">
        <img class="gsk-logo-icon" src="assets/logos/landscape_white.svg" alt="gsk logo" />
      </a>
    </groupui-grid-col>
    <!-- right side of the header -->
    <groupui-grid-col class="internal-banner">
      <!-- internal banner -->
      <img type="image/svg+xml" src="assets/internal-banner/Internal-Tag-Light.svg" alt="internal banner" />
      <!-- language selection -->
      <app-language-dropdown></app-language-dropdown>
      <!-- logout functionality -->
      <app-logout-button></app-logout-button>
    </groupui-grid-col>
  </groupui-grid-row>
</groupui-header>
