import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@ui/ui.module';
import { ShellComponent } from './shell.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { UtilsModule } from '@utils/utils.module';

/**
 * This module contains all necessary components for the app shell
 */
@NgModule({
  declarations: [ShellComponent, FooterComponent, HeaderComponent],
  imports: [
    CommonModule,
    GroupUiModule,
    UiModule,
    UtilsModule,
    TranslateModule,
    NgxExtendedPdfViewerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    ShellComponent
  ]
})
export class ShellModule { }
