import { Pipe, PipeTransform } from '@angular/core';

/**
 * Use a placeholder if no data is available
 */
@Pipe({ name: 'placeholder' })
export class PlaceholderPipe implements PipeTransform {
  transform(data: string | string[] | number | boolean | Date | undefined | null, extension: string = '-'): string {
    return data === null || data === undefined || data === '' ? extension :
      data === true ? 'key-data.battery-information.field-id-yes' :
        (data === false ? 'key-data.battery-information.field-id-no' : data.toString());
  }
}
