<!-- app content -->
<!-- only if a UUID is available, the app content will be displayed -->
<div *ngIf="uuid !== ''" [class.isCreatingPDF]="pdfService.isPresenting" class="container">
  <!-- app view -->
  <app-shell class="content">
    <router-outlet></router-outlet>
  </app-shell>
  <!-- print/download view -->
  <div class="pdf-content">
    <router-outlet name="pdf"></router-outlet>
  </div>
</div>
