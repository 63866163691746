<div class="dashboard" [class.hidden]="loadingIndicatorService.loading$ | async">
  <div class="dashboard-card-container">
    <groupui-grid-row class="version-container">
      <groupui-grid-col class="software-card" l="6" m="6" s="6">
        <app-key-readouts [readoutNumber]="keyReadouts.readout_count"></app-key-readouts>
      </groupui-grid-col>
      <groupui-grid-col class="software-card" l="6" m="6" s="6">
        <app-software-version [version]="keyReader.software"></app-software-version>
        <app-firmware-version [version]="keyReader.firmware"></app-firmware-version>
      </groupui-grid-col>
    </groupui-grid-row>
  </div>
</div>
