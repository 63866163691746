import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaceholderPipe } from './pipes/placeholder/placeholder.pipe';
import { SanitizerPipe } from './pipes/sanitizer/sanitizer.pipe';

// components contained in the utils folder
const COMPONENTS = [
  PlaceholderPipe,
  SanitizerPipe
];

/**
 * This module contains all necessary utils components
 */
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: COMPONENTS,
  exports: [
    COMPONENTS
  ]
})
export class UtilsModule {
}
