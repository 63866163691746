<groupui-card background="white">
  <div class="wrapper">
    <!-- If an icon is available, it is shown on the left side of the card -->
    <div *ngIf="icon" class="icon-container">
      <img src="{{icon}}" alt=""/>
    </div>
    <div class="content">
      <!-- If additoinal information are available, they should shown on the top of the card -->
      <div  class="additional-info">
        <groupui-tag *ngFor="let text of addInfoText" variant="secondary">{{ 'icon-card.add-info-text.' + text | translate }}</groupui-tag>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</groupui-card>
