import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataGroupsConstants } from '@utils/constants/data-groups.constants';
import { DataStatusConstants } from '@utils/constants/data-status.constants';
import { MapService } from '@services/map/map.service';
import { TranslationPathConstants } from '@utils/constants/translation-path.constants';
import { MissingDataService } from '@services/missing-data/missing-data.service';
import { IVehicleColor } from '@utils/interfaces/key-readout/vehicle-data/vehicle-color.interface';
import { ToggleStateService } from '@services/toggle-state/toggle-state.service';
import { BehaviorSubject } from 'rxjs';

/**
 * This component contains the interior and exterior color information
 */
@Component({
  selector: 'app-color-information',
  templateUrl: './color-information.component.html',
})
export class ColorInformationComponent implements OnChanges {
  // interior and exterior color information
  @Input() vehicleColor?: IVehicleColor;

  // path and name of the card icon
  readonly iconPath = 'assets/icons/key-data/car-color-24.svg';

  // color path, which is needed to map the translation from translation JSON
  readonly translationPath = TranslationPathConstants.colorInformation;

  // additional information which should displayed on the icon card
  addInfoText: string[] = [];

  // contains the last state of the toggle element
  private _displayData = new BehaviorSubject<boolean>(true);
  // share the last state of the toggle element
  public displayData$ = this._displayData.asObservable();
  // flag if the incomming data is empty
  isEmpty = true;
  // flag if show empty fields on the key data is selected
  showEmpty = true;

  /**
   * Creates an instance of ColorInformationComponent.
   *
   * @param missingDataService Checks whether the incoming data are empty
   * @param mapService map the fieldIds into a form, readable by the TranslationModule
   * @param toggleStateService stores and shares the state of the "key-data" toggle
   */
  constructor(
    public mapService: MapService,
    private missingDataService: MissingDataService,
    public toggleStateService: ToggleStateService
  ) {
    toggleStateService.toggleState$.subscribe((data: boolean) => {
      this.showEmpty = data;
      this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const vehicleColor = changes.vehicleColor.currentValue;

    // check if the vehicle condition data are available
    this.isEmpty = this.missingDataService.isDataEmpty(
      { color: vehicleColor },
      DataGroupsConstants.colorInformationData
    );

    this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);

    if (this.isEmpty) {
      if (
        !this.addInfoText.includes(DataStatusConstants.noColorInformationData)
      ) {
        // add the information of missing data to the icon card
        this.addInfoText.push(DataStatusConstants.noColorInformationData);
      }
    } else {
      this.addInfoText = [];
    }
  }
}
