import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataStatusConstants } from '@utils/constants/data-status.constants';
import { DataGroupsConstants } from '@utils/constants/data-groups.constants';
import { MissingDataService } from '@services/missing-data/missing-data.service';
import { MapService } from '@services/map/map.service';
import { TranslationPathConstants } from '@utils/constants/translation-path.constants';
import { ToggleStateService } from '@services/toggle-state/toggle-state.service';
import { BehaviorSubject } from 'rxjs';
import {IBatteryChargeLevel} from "@utils/interfaces/key-readout/service-key-data/battery-charge-level.interface";
import {
  IElectricVehicleInformation
} from "@utils/interfaces/key-readout/service-key-data/electric-vehicle-information.interface";

/**
 * This component contains the battery information
 */
@Component({
  selector: 'app-battery-information',
  templateUrl: './battery-information.component.html',
})
export class BatteryInformationComponent implements OnChanges {
  // Battery charge level information
  @Input() batteryChargeLevel!: IBatteryChargeLevel;
  // Electric vehicle information
  @Input() electricVehicleInformation!: IElectricVehicleInformation;

  // additional information which should displayed on the icon card
  addInfoText: string[] = [];
  isVisible = true;

  // path and name of the card icon
  readonly iconPath = 'assets/icons/key-data/battery-basic-24.svg';
  // battery charge level path, which is needed to map the translation from translation JSON
  readonly batteryChargeLevelTranslationPath =
    TranslationPathConstants.batteryInformation;
  // electric vehicle information path, which is needed to map the translation from translation JSON
  readonly electricVehicleInformationTranslationPath =
    TranslationPathConstants.batteryInformation;

  // contains the last state of the toggle element
  private _displayData = new BehaviorSubject<boolean>(true);
  // share the last state of the toggle element
  public displayData$ = this._displayData.asObservable();

  // flag if show empty fields on the key data is selected
  showEmpty = true;
  // flag if the incoming data is empty
  isEmpty = true;

  /**
   * Creates an instance of BatteryInformationComponent.
   *
   * @param missingDataService Checks whether the incoming data are empty
   * @param mapService map the fieldIds into a form, readable by the TranslationModule
   * @param toggleStateService stores and shares the state of the "key-data" toggle
   */
  constructor(
    public mapService: MapService,
    private missingDataService: MissingDataService,
    public toggleStateService: ToggleStateService
  ) {
    toggleStateService.toggleState$.subscribe((data: boolean) => {
      this.showEmpty = data;
      this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const batteryChargeLevel = changes.batteryChargeLevel.currentValue;
    const electricVehicleInformation =
      changes.electricVehicleInformation.currentValue;

    // check if the vehicle condition data are available
    this.isEmpty = this.missingDataService.isDataEmpty(
      { batteryChargeLevel, electricVehicleInformation },
      DataGroupsConstants.batteryInformationData
    );

    this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);

    if (this.isEmpty) {
      if (
        !this.addInfoText.includes(DataStatusConstants.noBatteryInformationData)
      ) {
        this.addInfoText.push(DataStatusConstants.noBatteryInformationData);
      }
    } else {
      this.addInfoText = [];
    }
  }
}
