import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {CookieService} from 'ngx-cookie-service';
import {UuidService} from "@services/uuid/uuid.service";
import {catchError} from "rxjs/operators";
import {LogoutService} from "@services/logout/logout.service";

/**
 * TODO
 */
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  /**
   *
   * @param cookieService get cookie information
   * @param logoutService service that is responsible for logout
   */
  constructor(private cookieService: CookieService,private logoutService:LogoutService) {
  }

  /**
   * TODO
   *
   * @param request
   * @param next
   * @returns
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Intercept the request and perform any necessary actions on /da endpoints
    if (request.url.includes('da/')) {
      // Add authorization header if no cookies are found in the request
      const uuidCookie = this.cookieService.get('uuid');
      // If cookie is found in the request, proceed
      if (uuidCookie)
        return this.redirectToInvalidSessionPage(next, request);
      else {
        //Otherwise, if cookie is not found in the request, set x-gsk-uuid header with value from initial data service
        const initialDataUuid = UuidService.getUuid();
        const modifiedRequest = request.clone({
          headers: request.headers.set('x-gsk-uuid', initialDataUuid)
        });
        return this.redirectToInvalidSessionPage(next, modifiedRequest);
      }
    } else {
      return this.redirectToInvalidSessionPage(next, request);
    }
  }

  public redirectToInvalidSessionPage(next: HttpHandler, request: HttpRequest<any>) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.logoutService.redirectToInvalidSessionPage();
          return throwError(() => new HttpErrorResponse({status: 401, statusText: "User unauthorized"}));
        } else {
          return next.handle(request);
        }
      })
    );
  }
}
