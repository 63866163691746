import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Service contains the handling of the user data
 */
@Injectable({
  providedIn: 'root'
})
export class GetUserDataService {

  /**
   * Creates an instance of the GetUserDataService.
   *
   * @param http  methods to perform HTTP requests
   */
  constructor(private http: HttpClient) { }

  /**
   * This function calls da/userdata endpoint to retrieve user's market code and importer admin flag.
   *
   * @return an userData object with two fields marketCode and importerAdminFlag.
   */
  public async getUserData(): Promise<any> {
    // Part 1 - Mock data (local) -------------------------------------------//
    // If the application should be started with local mock data,
    // part 1 must be commented in and part 2 must be commented out.
    //
    // const path = window.location.pathname + '/../assets/data/userdata_mock.json';
    // return await this.http.get<any>(path).toPromise();
    // ----------------------------------------------------------------------//

    // Part 2 - Real data (AWS) ---------------------------------------------//
    // If the application should be started with real data,
    // part 2 must be commented in and part 1 must be commented out.
    //
    return await this.http.get<any>('da/userdata').toPromise()
  }

}
