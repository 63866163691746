/**
 * Prefixes for the mapping of the translations in the PDFContentService
 */
export class TranslationPathPDFConstants {
  public static readonly vehicleData: string = 'data';
  public static readonly colorInformation: string = 'color';
  public static readonly distances: string = 'distances';
  public static readonly fluidLevels: string = 'fluid-levels';
  public static readonly warnings: string = 'warning-messages';
  public static readonly batteryInformation: string = 'battery-information';
  public static readonly energyConsumerHints: string = 'energy-consumer-hints';
  public static readonly emission: string = 'emission';
  public static readonly diagnosticTroubleCodes: string = 'diagnostic-trouble-codes';
}
