/**
 * Prefixes for the mapping of the translations in the TranslationPipe
 */
export class TranslationPathConstants {
  public static readonly vehicleData: string = 'key-data.data.';
  public static readonly colorInformation: string = 'key-data.color.';
  public static readonly distances: string = 'key-data.distances.';
  public static readonly fluidLevels: string = 'key-data.fluid-levels.';
  public static readonly warnings: string = 'key-data.warning-messages.';
  public static readonly batteryInformation: string = 'key-data.battery-information.';
  public static readonly energyConsumerHints: string = 'key-data.energy-consumer-hints.';
  public static readonly emission: string = 'key-data.emission.';
  public static readonly diagnosticTroubleCodes: string = 'key-data.diagnostic-trouble-codes.';
}
