import { Component, Input } from '@angular/core';
import { IVersion } from '@utils/interfaces/version.interface';

/**
 * Component of the software status
 */
@Component({
  selector: 'app-software-version',
  templateUrl: './software-version.component.html'
})
export class SoftwareVersionComponent {
  // Current status of the software version
  @Input() set version(version: IVersion) {
    this.currentVersion = version;
  };

  // currently selected version
  currentVersion: IVersion = { currentVersion: ''};
  // What version type is it (software or firmware)
  readonly versionType = 'software';
}
