import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PdfPresentaionService } from '@services/pdf-presentation/pdf-presentation.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { UuidService } from '@services/uuid/uuid.service';

import { WebMessageAction } from '@utils/interfaces/web-messages/web-message-action.enum';
import { UuidMessage } from '@utils/interfaces/web-messages/uuid.interface';
import { WebMessageFormat } from '@utils/interfaces/web-messages/web-message-format.interface';
import { DefaultSettingsConstants } from '@utils/constants/default-settings.constants';

/**
 * Global initialization of the Windows properties of the Webview
 */
declare global {
  interface Window {
    chrome?: {
      webview?: any;
    };
  }
}

/**
 * Root component of GSK
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // uuid of frontend that comes from trayapp
  public uuid = '';

  /**
   * Creates an instance of AppComponent.
   *
   * @param defaultSettings Recurring default values
   * @param pdfService controls the way a generated PDF is presented
   * @param translateService communication with the internationalization (i18n) library
   * @param localStorageService handles the local storage communication
   * @param initialDataService to get information about the uuid
   */
  constructor(
    private defaultSettings: DefaultSettingsConstants,
    public pdfService: PdfPresentaionService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    public initialDataService: UuidService
  ) {}

  /**
   * Check if a preselected lanuage is available
   */
  ngOnInit(): void {
    // get the UUID
    this.requestToGetUUIDFromTrayApp();
    // Use the default language or the previously selected language from the local storage
    const preselectedLanguage: string = this.localStorageService.getItem(
      this.defaultSettings.localStorageLanguageVariable
    );
    if (preselectedLanguage !== '') {
      this.translateService.setDefaultLang(preselectedLanguage);
    } else {
      this.translateService.setDefaultLang(
        this.defaultSettings.defaultLanguage
      );
    }
  }

  /**
   * Listen to the WebMessages and get the UUID from the TrayApp
   */
  public requestToGetUUIDFromTrayApp(): void {
    window.chrome?.webview.addEventListener('message', (event: MessageEvent) => {
      this.getUuidFromWebMessage(event);
    });
    const message: WebMessageFormat = {
      action: WebMessageAction.GET_UUID,
      data: '',
    };
    window.chrome?.webview.postMessage(JSON.stringify(message));
  }

  /**
   * Get UUID from webmessage action SET_UUID and set the UUID in the UuidService.
   *
   * @param event message event with the Webmessage action and data
   */
  public getUuidFromWebMessage(event: MessageEvent): void {
    const { action, data } = JSON.parse(event.data);
    // Handle only messages with 'SEND_UUID_AND_VERSION_INFORMATION' action, ignore others
    if (action != WebMessageAction.SET_UUID) {
      return;
    }
    // Extract uuid and version information from event data which is a JSON string
    const uuidInformation: UuidMessage = JSON.parse(data);
    UuidService.setUuid(uuidInformation.Uuid);
    this.uuid = uuidInformation.Uuid;
  }
}
