import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

/**
 * This service handles the session storage communication
 */
@Injectable({
  providedIn: 'root'
})
export class SessionStorageService implements OnDestroy {
  // contains the last edited session storage element
  private onSubject = new Subject<{ key: string; value: any }>();

  // share the last edited session storage element
  public changes: Observable<{ key: string; value: any }> = this.onSubject.asObservable().pipe(share());

  /**
   * Creates an instance of LocalStorageService.
   */
  constructor() {
    // subscribe to the session storage event
    this.start();
  }

  ngOnDestroy(): void {
    // unsubscribe to the session storage event
    this.stop();
  }

  /**
   * Returns all storage elements as key value pair
   *
   * @returns key value pair
   */
  public getStorage(): Array<{ key: string; value: string | null }> {
    const storage = [];
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i)?.toString();
      if (key) {
        const value = sessionStorage.getItem(key);
        storage.push({
          key,
          value
        });
      }
    }

    return storage;
  }

  /**
   * Return a storage element value for a given key
   *
   * @param key key of the storage element
   * @returns value for a given key
   */
  public getItem(key: string): string {
    const item = sessionStorage.getItem(key);
    return item !== null ? item : '';
  }

  /**
   * Add a key value pair to the session storage
   *
   * @param key defines the data set
   * @param value variable that belongs to the set
   */
  public setItem(key: string, value: string): void {
    sessionStorage.setItem(key, value);
    this.onSubject.next({ key, value });
  }

  /**
   * Remove a key value pair from the session storage
   *
   * @param key key of the element to remove
   */
  public removeItem(key: string): void {
    sessionStorage.removeItem(key);
    this.onSubject.next({ key, value: null });
  }

  /**
   * Remove all key value pairs from the session storage
   */
  public removeAllItems(): void {
    sessionStorage.clear();
    this.onSubject.complete();
  }

  /**
   * Start listening to the storage event
   */
  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this), false);
  }

  /**
   * listening to the storage to recognize new events
   *
   * @param event storage event
   */
  private storageEventListener(event: StorageEvent): void {
    if (event.storageArea === sessionStorage) {
      let v;
      try {
        if (event.newValue) {
          v = JSON.parse(event.newValue);
        }
      }
      catch (e) { v = event.newValue; }
      if (event.key) {
        this.onSubject.next({ key: event.key, value: v });
      }
    }
  }

  /**
   * Stop listening to the storage event
   */
  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
