// font imports for the different languages in the PdfPresentationService
export const fonts = {
 IL: {
    normal: 'https://cdn.jsdelivr.net/gh/notofonts/notofonts.github.io/fonts/NotoSansHebrew/full/ttf/NotoSansHebrew-Regular.ttf',
    bold: 'https://cdn.jsdelivr.net/gh/notofonts/notofonts.github.io/fonts/NotoSansHebrew/full/ttf/NotoSansHebrew-Bold.ttf',
  },
  NotoSans: {
    normal: 'https://cdn.jsdelivr.net/gh/notofonts/notofonts.github.io/fonts/NotoSans/full/ttf/NotoSans-Regular.ttf',
    bold: 'https://cdn.jsdelivr.net/gh/notofonts/notofonts.github.io/fonts/NotoSans/full/ttf/NotoSans-Bold.ttf',
  },
  JP: {
    normal: 'https://cdn.jsdelivr.net/npm/japanese-fonts-css@0.3.0/dist/assets/NotoSansMonoCJKjp-Regular.min.ttf',
    bold: 'https://cdn.jsdelivr.net/npm/japanese-fonts-css@0.3.0/dist/assets/NotoSansMonoCJKjp-Bold.min.ttf',
  },
  KR: {
    normal: 'https://cdn.jsdelivr.net/npm/@kfonts/nanum-square@0.1.3/NanumSquareR.ttf',
    bold: 'https://cdn.jsdelivr.net/npm/@kfonts/nanum-square@0.1.3/NanumSquareB.ttf',
  },
  CN: {
    normal: 'fzhei-jt.ttf',
    bold: 'fzhei-jt.ttf',
    italics: 'fzhei-jt.ttf',
    bolditalics: 'fzhei-jt.ttf'
  }
};
