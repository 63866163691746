import { IEmission } from '../interfaces/key-readout/emission-data/emission.interface';
import { IServiceKeyData } from '../interfaces/key-readout/service-key-data/service-key-data.interface';
import { IVehicle } from '../interfaces/key-readout/vehicle-data/vehicle.interface';

/**
 * The key data can be distinguished between the following data groups.
 */
export class DataGroupsConstants {
  public static readonly keyData: Array<keyof IServiceKeyData> = [
    'mileage',
    'serviceIntervalDisplay',
    'fluidLevels',
    'adBlue',
    'remainingRangesOfDrives',
    'oilNorm',
    'warningMessagesActive',
    'warningMessagesHistory',
    'diagnosticTroubleCodes',
    'batteryChargeLevel',
    'electricVehicleInformation',
    'energyConsumers',
    'energyHints'
  ];

  public static readonly conditionData: Array<keyof IServiceKeyData> = [
    'mileage',
    'serviceIntervalDisplay',
    'fluidLevels',
    'adBlue',
    'remainingRangesOfDrives',
    'warningMessagesActive',
    'warningMessagesHistory',
    'batteryChargeLevel',
    'electricVehicleInformation',
    'energyConsumers',
    'energyHints'
  ];

  public static readonly warningsData: Array<keyof IServiceKeyData> = [
    'warningMessagesActive',
    'warningMessagesHistory'
  ];

  public static readonly fluidLevelsData: Array<keyof IServiceKeyData> = [
    'fluidLevels',
    'adBlue',
    'remainingRangesOfDrives'
  ];

  public static readonly energyConsumerHintsData: Array<keyof IServiceKeyData> = [
    'energyConsumers',
    'energyHints'
  ];

  public static readonly distancesData: Array<keyof IServiceKeyData> = [
    'mileage',
    'serviceIntervalDisplay'
  ];

  public static readonly batteryInformationData: Array<keyof IServiceKeyData> = [
    'batteryChargeLevel',
    'electricVehicleInformation'
  ];

  public static readonly diagnosticData: Array<keyof IServiceKeyData> = [
    'diagnosticTroubleCodes'
  ];

  public static readonly oilNormData: Array<keyof IServiceKeyData> = [
    'oilNorm'
  ];

  public static readonly emissionData: Array<keyof IEmission> = [
    'nedcData',
    'wltpData'
  ];

  public static readonly colorInformationData: Array<keyof IVehicle> = [
    'color'
  ];
}

