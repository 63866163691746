<app-icon-card [icon]="iconPath" [addInfoText]="addInfoText" *ngIf="displayData$ | async">
  <!-- first col (energy consumer) -->
  <groupui-grid-row>
    <groupui-grid-col xl="6" l="12" m="12" s="12" class="unsetPaddingRight">
      <!-- title -->
      <groupui-grid-row>
        <groupui-grid-col class="fieldName value textWrapFloatRight">
          {{ mapService.mapFieldIdToPath(energyConsumers.fieldId, translationPath) | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- 1 consumer -->
      <groupui-grid-row>
        <groupui-grid-col l="1" m="1" s="1" class="fieldName">
          {{mapService.mapFieldIdToPath(energyConsumers.firstConsumer.fieldId, translationPath) | placeholder}}
        </groupui-grid-col>
        <groupui-grid-col l="11" m="11" s="11" class="value">
          {{ energyConsumers.firstConsumer.value | placeholder }}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- 2 consumer -->
      <groupui-grid-row>
        <groupui-grid-col l="1" m="1" s="1" class="fieldName">
          {{mapService.mapFieldIdToPath(energyConsumers.secondConsumer.fieldId, translationPath) | placeholder}}
        </groupui-grid-col>
        <groupui-grid-col l="11" m="11" s="11" class="value">
          {{ energyConsumers.secondConsumer.value | placeholder }}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- 3 consumer -->
      <groupui-grid-row>
        <groupui-grid-col l="1" m="1" s="1" class="fieldName">
          {{mapService.mapFieldIdToPath(energyConsumers.thirdConsumer.fieldId, translationPath) | placeholder}}
        </groupui-grid-col>
        <groupui-grid-col l="11" m="11" s="11" class="value">
          {{ energyConsumers.thirdConsumer.value | placeholder }}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- energy potential -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(energyConsumers.energyPotential.fieldId, translationPath)"
        [value]="energyConsumers.energyPotential.value" [unit]="energyConsumers.energyPotential.unit">
      </app-description-value-cell>

      <!-- whitespace -->
      <groupui-grid-row class="hideXL">
        &nbsp;
      </groupui-grid-row>
    </groupui-grid-col>

    <!-- second col (energy hints) -->
    <groupui-grid-col xl="6" l="12" m="12" s="12" class="unsetPaddingRight">
      <!-- title -->
      <groupui-grid-row>
        <groupui-grid-col class="fieldName value textWrapFloatRight">
          {{mapService.mapFieldIdToPath(energyHints.fieldId, translationPath) | placeholder }}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- 1 hint -->
      <groupui-grid-row>
        <groupui-grid-col l="1" m="1" s="1" class="fieldName hideXL">
          {{mapService.mapFieldIdToPath(energyHints.firstEnergyHint.fieldId, translationPath) | placeholder}}
        </groupui-grid-col>
        <groupui-grid-col xl="12" l="11" m="11" s="11" class=" value">
          {{ energyHints.firstEnergyHint.value | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>

      <!-- 2 hint -->
      <groupui-grid-row>
        <groupui-grid-col l="1" m="1" s="1" class="fieldName hideXL">
          {{mapService.mapFieldIdToPath(energyHints.secondEnergyHint.fieldId, translationPath) | placeholder}}
        </groupui-grid-col>
        <groupui-grid-col xl="12" l="11" m="11" s="11" class=" value">
          {{ energyHints.secondEnergyHint.value | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>

      <!-- 3 hint -->
      <groupui-grid-row>
        <groupui-grid-col l="1" m="1" s="1" class="fieldName hideXL">
          {{ mapService.mapFieldIdToPath(energyHints.thirdEnergyHint.fieldId, translationPath) | placeholder}}
        </groupui-grid-col>
        <groupui-grid-col xl="12" l="11" m="11" s="11" class=" value">
          {{ energyHints.thirdEnergyHint.value | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
    </groupui-grid-col>

  </groupui-grid-row>
</app-icon-card>
