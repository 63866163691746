import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@ui/ui.module';
import { SoftwareVersionComponent } from './components/software-version/software-version.component';
import { FirmwareVersionComponent } from './components/firmware-version/firmware-version.component';
import { KeyReadoutsComponent } from './components/key-readouts/key-readouts.component';

/**
 * This module contains all necessary components for the dashboard
 */
@NgModule({
  declarations: [
    DashboardComponent,
    SoftwareVersionComponent,
    FirmwareVersionComponent,
    KeyReadoutsComponent
  ],
  imports: [CommonModule, GroupUiModule, UiModule, TranslateModule],
  exports: [DashboardComponent],
})
export class DashboardModule {}
