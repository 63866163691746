import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHistoricalReadoutRecord } from '@utils/interfaces/historical-readout/historical-readout-record.interface';
import { IPagination } from '@utils/interfaces/pagination/pagination.interface';
import { IKeyDataResponse } from '@utils/interfaces/key-readout/key-readout-response.interface';
import { HistoricalReadoutFilterResponse } from '@utils/interfaces/historical-readout/historical-readout-filter.interface';
import { LoadingIndicatorService } from '../loading-indicator/loading-indicator.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoricalReadoutService {
  /**
   * Creates an instance of the HistoricalReadoutService
   *
   * @param loadingIndicatorService contains the handling of the loading indicator
   * @param http  methods to perform HTTP requests
   */
  constructor(
    private http: HttpClient,
    private loadingIndicatorService: LoadingIndicatorService
  ) {}

  /**
   * Call the '/da/keydata/records' endpoint to get the paginated historical readout table data
   *
   * @param pageSize page size of the pagination response
   * @param pageNumber page number of the pagination response
   * @param body contains the filters that are used to filter pagination data
   */
  getHistoricalData(
    pageSize: number = 50,
    pageNumber: number = 1,
    body = {}
  ): Promise<IPagination<IHistoricalReadoutRecord>> {
    this.loadingIndicatorService.show();
    // request header
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const options = {
      headers
    };
    const url = `/da/keydata/records?page_size=${pageSize}&page_number=${pageNumber}`;
    return this.http
      .post<IPagination<IHistoricalReadoutRecord>>(url, body, options)
      .toPromise()
      .then(
        (filter) => {
          this.loadingIndicatorService.hide();
          return filter;
        },
        // In case of error
        (err: HttpErrorResponse) => {
          this.loadingIndicatorService.hide();
          return {
            page_number: null,
            page_size: null,
            total_pages: null,
            total_count: null,
            page_contents: []
          }
        }
      );
  }

  /**
   * Call the '/da/keydata/details/${record_id}' endpoint to get the historical data
   *
   * @param record_id record id of the past key-readout to retrieve the data
   * @returns historical vehicle data
   */
  getHistoricalRecord(record_id: number): Observable<IKeyDataResponse> {
    const url = `/da/keydata/details/${record_id}`;
    return this.http.post<IKeyDataResponse>(url, null);
  }

  /**
   * Call the '/da/keydata/filter' endpoint to get the brand codes used for filtering
   */
  getHistoricalDataFilters(): Promise<HistoricalReadoutFilterResponse> {
    this.loadingIndicatorService.show();
    // request header
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const options = {
      headers
    };
    const url = `/da/keydata/filter`;
    return this.http
      .post<HistoricalReadoutFilterResponse>(url, {}, options)
      .toPromise()
      .then(
        (res) => {
          this.loadingIndicatorService.hide();
          return res;
        },
        // In case of error
        (err: HttpErrorResponse) => {
          this.loadingIndicatorService.hide();
          return { brandCodes: [], vins: []};
        }
      );
  }
}
