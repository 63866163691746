import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/*
  This toggle-state service keeps the state of the toggle in the "key-data" component and shares it across all the "key-data"
  subcomponents.
*/
@Injectable({
  providedIn: 'root'
})
export class ToggleStateService {
  // contains the last toggle state -> default state is true
  private _toggleState = new BehaviorSubject<boolean>(true);
  // share the last toggle state
  public toggleState$ = this._toggleState.asObservable();

/**
 * set the toggle state
 *
 * @param value value of the toggle
 */
  setToggleState(value: boolean): void {
    this._toggleState.next(value);
  }
}
