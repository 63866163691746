import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MissingDataService } from '@services/missing-data/missing-data.service';
import { DataStatusConstants } from '@utils/constants/data-status.constants';
import { DataGroupsConstants } from '@utils/constants/data-groups.constants';
import { MapService } from '@services/map/map.service';
import { TranslationPathConstants } from '@utils/constants/translation-path.constants';
import { IOilNorm } from '@utils/interfaces/key-readout/service-key-data/oil-norm.interface';
import { IRemainingRangesOfDrives } from '@utils/interfaces/key-readout/service-key-data/remaining-ranges-of-drives.interface';
import { IFluidLevels } from '@utils/interfaces/key-readout/service-key-data/fluid-levels.interface';
import { IFieldIdValuePair } from '@utils/interfaces/key-readout/id-value-pair.interface';
import { ToggleStateService } from '@services/toggle-state/toggle-state.service';
import { BehaviorSubject } from 'rxjs';

/**
 * This component cointains a overview of the levels
 */
@Component({
  selector: 'app-fluid-levels',
  styleUrls: ['./fluid-levels.component.scss'],
  templateUrl: './fluid-levels.component.html',
})
export class FluidLevelsComponent implements OnChanges {
  // Oil norm information
  @Input() oilNorm!: IOilNorm;
  // Remaining ranges of drives information
  @Input() remainingRangesOfDrives!: IRemainingRangesOfDrives;
  // Fluid levels information
  @Input() fluidLevels!: IFluidLevels;
  // AdBlue information
  @Input() adBlue!: IFieldIdValuePair;
  // additional information which should displayed on the icon card
  public addInfoText: string[] = [];
  // path and name of the card icon
  readonly iconPath = 'assets/icons/key-data/consumable-liquid-24.svg';
  // fluid level path, which is needed to map the translation from translation JSON
  readonly translationPath = TranslationPathConstants.fluidLevels;
  // contains the last state of the toggle element
  private _displayData = new BehaviorSubject<boolean>(true);
  // share the last state of the toggle element
  public displayData$ = this._displayData.asObservable();

  // flag if show empty fields on the key data is selected
  showEmpty = true;
  // flag if the incomming data is empty
  isEmpty = true;

  /**
   * Creates an instance of FluidLevelsComponent.
   *
   * @param missingDataService Checks whether the incoming data are empty
   * @param mapService map the fieldIds into a form, readable by the TranslationModule
   * @param toggleStateService stores and shares the state of the "key-data" toggle
   */
  constructor(
    public missingDataService: MissingDataService,
    public mapService: MapService,
    public toggleStateService: ToggleStateService
  ) {
    toggleStateService.toggleState$.subscribe((data: boolean) => {
      this.showEmpty = data;
      this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const fluidLevels = changes.fluidLevels.currentValue;
    const adBlue = changes.adBlue.currentValue;
    const remainingRangesOfDrives =
      changes.remainingRangesOfDrives.currentValue;
    const oilNorm = changes.oilNorm.currentValue;

    // check if the vehicle condition data are available
    const isFluidDataEmpty = this.missingDataService.isDataEmpty(
      { fluidLevels, adBlue, remainingRangesOfDrives },
      DataGroupsConstants.fluidLevelsData
    );

    // check if the oil norm data are available
    const isNormDataEmpty = this.missingDataService.isDataEmpty(
      { oilNorm },
      DataGroupsConstants.oilNormData
    );

    this.isEmpty = isFluidDataEmpty && isNormDataEmpty;
    this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);

    if (isFluidDataEmpty) {
      if (!this.addInfoText.includes(DataStatusConstants.noFluidLevelsData)) {
        // add the information of missing data to the icon card
        this.addInfoText.push(DataStatusConstants.noFluidLevelsData);
      }
    } else if (
      this.addInfoText.includes(DataStatusConstants.noFluidLevelsData)
    ) {
      const index = this.addInfoText.indexOf(
        DataStatusConstants.noFluidLevelsData,
        0
      );
      if (index > -1) {
        this.addInfoText.splice(index, 1);
      }
    }

    if (isNormDataEmpty) {
      if (!this.addInfoText.includes(DataStatusConstants.noOilNormData)) {
        // add the information of missing data to the icon card
        this.addInfoText.push(DataStatusConstants.noOilNormData);
      }
    } else if (this.addInfoText.includes(DataStatusConstants.noOilNormData)) {
      const index = this.addInfoText.indexOf(
        DataStatusConstants.noOilNormData,
        0
      );
      if (index > -1) {
        this.addInfoText.splice(index, 1);
      }
    }
  }
}
