/**
 * If complete data groups are missing, these keys are used in the translation JSON to set the corresponding message to IconCard.
 */
export class DataStatusConstants {
  public static readonly noKeyData: string = 'noKeyData';
  public static readonly noConditionData: string = 'noConditionData';
  public static readonly noWarningsData: string = 'noConditionData';
  public static readonly noFluidLevelsData: string = 'noConditionData';
  public static readonly noEnergyConsumerHintsData: string = 'noConditionData';
  public static readonly noDistancesData: string = 'noConditionData';
  public static readonly noBatteryInformationData: string = 'noConditionData';
  public static readonly noColorInformationData: string = 'noConditionData';
  public static readonly noDiagnosticData: string = 'noDiagnosticData';
  public static readonly noOilNormData: string = 'noOilNormData';
  public static readonly noEmissionData: string = 'noEmissionData';
}
