import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MissingDataService } from '@services/missing-data/missing-data.service';
import { DataStatusConstants } from '@utils/constants/data-status.constants';
import { DataGroupsConstants } from '@utils/constants/data-groups.constants';
import { MapService } from '@services/map/map.service';
import { TranslationPathConstants } from '@utils/constants/translation-path.constants';
import { IEnergyConsumers } from '@utils/interfaces/key-readout/service-key-data/energy-consumers.interface';
import { IEnergyHints } from '@utils/interfaces/key-readout/service-key-data/energy-hints.interface';
import { ToggleStateService } from '@services/toggle-state/toggle-state.service';
import { BehaviorSubject } from 'rxjs';

/**
 * This component cointains information regarding the top 3 energy consumers and hints
 * (that can be influencend by the customer)
 */
@Component({
  selector: 'app-energy-consumer-hints',
  templateUrl: './energy-consumer-hints.component.html',
})
export class EnergyConsumerHintsComponent implements OnChanges {
  // top 3 energy consumers
  @Input() energyConsumers!: IEnergyConsumers;
  // top 3 energy hints
  @Input() energyHints!: IEnergyHints;
  // additional information which should displayed on the icon card
  addInfoText: string[] = [];
  // path and name of the card icon
  readonly iconPath = 'assets/icons/key-data/plug-24.svg';
  // energy consumer and hint path, which is needed to map the translation from translation JSON
  readonly translationPath = TranslationPathConstants.energyConsumerHints;
  // contains the last state of the toggle element
  private _displayData = new BehaviorSubject<boolean>(true);
  // share the last state of the toggle element
  public displayData$ = this._displayData.asObservable();

  // flag if show empty fields on the key data is selected
  showEmpty = true;
  // flag if the incomming data is empty
  isEmpty = true;

  /**
   * Creates an instance of EnergyConsumerHintsComponent.
   *
   * @param missingDataService Checks whether the incoming data are empty
   * @param mapService map the fieldIds into a form, readable by the TranslationModule
   * @param toggleStateService stores and shares the state of the "key-data" toggle
   */
  constructor(
    public missingDataService: MissingDataService,
    public mapService: MapService,
    public toggleStateService: ToggleStateService
  ) {
    toggleStateService.toggleState$.subscribe((data: boolean) => {
      this.showEmpty = data;
      this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const energyConsumers = changes.energyConsumers.currentValue;
    const energyHints = changes.energyHints.currentValue;
    this.isEmpty = this.missingDataService.isDataEmpty(
      { energyConsumers: energyConsumers, energyHints: energyHints },
      DataGroupsConstants.energyConsumerHintsData
    );
    this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);

    // check if the vehicle condition data are available
    if (this.isEmpty) {
      if (
        !this.addInfoText.includes(
          DataStatusConstants.noEnergyConsumerHintsData
        )
      ) {
        // add the information of missing data to the icon card
        this.addInfoText.push(DataStatusConstants.noEnergyConsumerHintsData);
      }
    } else {
      this.addInfoText = [];
    }
  }
}
