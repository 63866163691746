import {Injectable} from '@angular/core';

/**
 * This service is used to handle the uuid coming from tray app web messages and as a replacement for uuid cookie
 */
@Injectable({
  providedIn: 'root'
})
export class UuidService {
  //Stores the uuid coming from trayapp
  private static uuid = "";

  /**
   * get the uuid
   *
   * @returns uuid as a string
   */
  public static getUuid(): string {
    return UuidService.uuid;
  }

  /**
   * set the uuid
   *
   * @param value uuid
   */
  public static setUuid(value: string): void {
    UuidService.uuid = value;
  }
}
