import { Component, Input } from '@angular/core';

/**
* Transforms the input value in case the value type is string[] into an comma separated values string.
 * Function never used in the code, commented for now.
*/
// function transformValue(value: any): any {
//   if(Array.isArray(value)){
//     return value.toString()
//   }
//   return value
// };

/**
 * This component displays a name/value or name/value/unit pair in a key data card
 */
@Component({
  selector: 'app-description-value-cell',
  templateUrl: './description-value-cell.component.html',
})
export class DescriptionValueCellComponent {
  // value of the name value pair
  @Input() value?: string | string[] | number | boolean | Date | undefined | null;
  // unit of the value if available
  @Input() unit?: string;
  // name of the name value pair
  @Input() fieldName = '';
  // suffix of the value for example '%'
  @Input() set suffix(suffix: string) {
    // display suffix only if data is also present
    if (this.fieldName !== '') {
      this.displaySuffix = suffix;
    }
  }
  // displayed suffix
  displaySuffix?: string;
}
