import { Component, Input } from '@angular/core';
import { MissingDataService } from '@services/missing-data/missing-data.service';
import { DataStatusConstants } from '@utils/constants/data-status.constants';
import { DataGroupsConstants } from '@utils/constants/data-groups.constants';
import { MapService } from '@services/map/map.service';
import { TranslationPathConstants } from '@utils/constants/translation-path.constants';
import { IDiagnosticTroubleCodes } from '@utils/interfaces/key-readout/service-key-data/diagnostic-trouble-code.interface';
import { ToggleStateService } from '@services/toggle-state/toggle-state.service';
import { BehaviorSubject } from 'rxjs';

/**
 * This component contains information regarding the diagnostic Trouble Codes (event memory entries)
 */
@Component({
  selector: 'app-diagnostic-trouble-code',
  templateUrl: './diagnostic-trouble-code.component.html',
  styleUrls: ['./diagnostic-trouble-code.component.scss'],
})
export class DiagnosticTroubleCodesComponent {
  // Diagnostic trouble codes information
  @Input() set troubleCodes(troubleCodes: IDiagnosticTroubleCodes[]) {
    this.troubleCodesData = troubleCodes;
    this.addInfoText = [];
    this.isEmpty = this.missingDataService.isDataEmpty(
      { diagnosticTroubleCodes: troubleCodes },
      DataGroupsConstants.diagnosticData
    );
    this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);

    // check if the vehicle diagnostic data are available
    if (this.isEmpty) {
      // add the information of missing data to the icon card
      this.addInfoText.push(DataStatusConstants.noDiagnosticData);
    }
  }
  // diagnostic trouble codes to display
  troubleCodesData?: IDiagnosticTroubleCodes[];
  // path and name of the card icon
  readonly iconPath = 'assets/icons/key-data/calendar-24.svg';
  // diagnostic trouble code path, which is needed to map the translation from translation JSON
  readonly translationPath = TranslationPathConstants.diagnosticTroubleCodes;
  // additional information which should displayed on the icon card
  addInfoText: string[] = [];

  // contains the last state of the toggle element
  private _displayData = new BehaviorSubject<boolean>(true);
  // share the last state of the toggle element
  public displayData$ = this._displayData.asObservable();

  // flag if show empty fields on the key data is selected
  showEmpty = true;
  // flag if the incomming data is empty
  isEmpty = true;

  /**
   * Creates an instance of DiagnosticTroubleCodesComponent.
   *
   * @param missingDataService Checks whether the incoming data are empty
   * @param mapService map the fieldIds into a form, readable by the TranslationModule
   * @param toggleStateService stores and shares the state of the "key-data" toggle
   */
  constructor(
    public missingDataService: MissingDataService,
    public mapService: MapService,
    public toggleStateService: ToggleStateService
  ) {
    toggleStateService.toggleState$.subscribe((data: boolean) => {
      this.showEmpty = data;
      this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);
    });
  }
}
