/**
 * Possible actions to handle the gdpr content on the edit gdpr page
 */
export enum EditGdprActionEnum {
  add = 'add',
  update = 'update',
  delete = 'delete',
  changeDefault = 'changeDefault',
  discardChanges = 'discardChanges',
  deleteDefault = 'deleteDefault',
  save = 'save'
}
