import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import languages from '@assets/data/languages.json';
import { ILanguage } from '@utils/interfaces/language.interface';

/**
 * collection of reusable map functions
 */
@Injectable({
  providedIn: 'root'
})
export class MapService {

  /**
   * Creates an instance of MapService.
   *
   * @param translateService communication with the internationalization (i18n) library
   * @param datePipe formats a date value according to locale rules
   */
  constructor(private translateService: TranslateService, private datePipe: DatePipe) { }

  /**
   * Concatinate the fieldId of the fieldname with the fieldId path to bring it to a form, readable by the TranslationModule.
   *
   * @param value fieldId of the fieldname
   * @param translationPath fieldId path of the translation JSON
   * @returns translated value
   */
  mapFieldIdToPath(value: string | undefined, translationPath: string = ''): string {
    let transValue = '';
    if (value) {
      this.translateService.get(translationPath + value).subscribe((trans: string) => transValue = trans);
    }
    return transValue;
  }

  /**
   * Map a string or date into a special date format
   *
   * @param date as a Date Object or string
   * @returns transformed Date to the date format: 'dd-MM-YY hh:mm'
   */
  mapDateOrPlaceholder(date: any): string | null {
    return date instanceof Date || typeof date === 'string' ? this.datePipe.transform(date, 'dd-MM-YY hh:mm') : '-';
  }

  /**
   * Should check if the incomming value is a number
   * @param val value to be checked if it is a number
   * @returns true if it is an number
   */
  isNumber(val: any): boolean {
    if (typeof val === 'number') {
      return true;
    }

    if (typeof val !== 'string') {
      return false;
    }

    if (val.trim() === '') {
      return false;
    }

    return !Number.isNaN(Number(val));
  }

  /**
   * Map snake and kebab case to camel case
   *
   * @param value string in snake and kebab case
   * @returns string in camel case
   */
  mapToCamel(value: string): string {
    return value.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  };

  /**
   * Map camel case to snake case
   *
   * @param value string in camel case
   * @returns string in snake case
   */
  mapToSnakeCase(value: string): string {
    return value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  /**
   * With a Contry code, a combination of the display country and the country code is mapped from an ILanguage array.
   * For example, the country code 'GB' is mapped to the string 'English - GB'.
   * @param code code of a country
   * @param placeholder is displayed if no entry is found for the country code searched for
   * @returns mapped string in the format 'display country - country code'
   */
  mapCountryCodeToDisplayCountryAndCountryCode(code: string | undefined, placeholder: string): string {
    const allLanguages: ILanguage[] = languages.languages;
    const mappedLanguage = allLanguages.find(({ countryCode }) => countryCode === code);
    return mappedLanguage ? mappedLanguage.displayCountry + ' - ' + mappedLanguage.countryCode : placeholder;
  }
}
