import { Component, Input } from '@angular/core';
import { MissingDataService } from '@services/missing-data/missing-data.service';
import { DataStatusConstants } from '@utils/constants/data-status.constants';
import { MapService } from '@services/map/map.service';
import { DataGroupsConstants } from '@utils/constants/data-groups.constants';
import { TranslationPathConstants } from '@utils/constants/translation-path.constants';
import { IEmission } from '@utils/interfaces/key-readout/emission-data/emission.interface';
import { ToggleStateService } from '@services/toggle-state/toggle-state.service';
import { BehaviorSubject } from 'rxjs';

/**
 * This component contains the emission data (wltp and nedc information)
 */
@Component({
  selector: 'app-emission',
  templateUrl: './emission.component.html',
})
export class EmissionComponent {
  // Electric vehicle information
  @Input() set emission(emission: IEmission) {
    this.emissionData = emission;
    this.addInfoText = [];

    this.isEmpty = this.missingDataService.isDataEmpty(
      { nedcData: emission.nedcData, wltpData: emission.wltpData },
      DataGroupsConstants.emissionData
    );

    this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);
    if (this.isEmpty) {
      // add the information of missing data to the icon card
      this.addInfoText.push(DataStatusConstants.noEmissionData);
    }
  }
  // nedc and wltp information
  emissionData?: IEmission;

  // additional information which should displayed on the icon card
  addInfoText: string[] = [];

  // path and name of the card icon
  readonly iconPath = 'assets/icons/key-data/CO2-cloud-24.svg';
  // emission path, which is needed to map the translation from translation JSON
  readonly translationPath = TranslationPathConstants.emission;

  // contains the last state of the toggle element
  private _displayData = new BehaviorSubject<boolean>(true);
  // share the last state of the toggle element
  public displayData$ = this._displayData.asObservable();

  // flag if show empty fields on the key data is selected
  showEmpty = true;
  // flag if the incomming data is empty
  isEmpty = true;

  /**
   * Creates an instance of EmissionComponent.
   *
   * @param missingDataService Checks whether the incoming data are empty
   * @param mapService map the fieldIds into a form, readable by the TranslationModule
   * @param toggleStateService stores and shares the state of the "key-data" toggle
   */
  constructor(
    public missingDataService: MissingDataService,
    public mapService: MapService,
    public toggleStateService: ToggleStateService
  ) {
    toggleStateService.toggleState$.subscribe((data: boolean) => {
      this.showEmpty = data;
      this._displayData.next((this.isEmpty && this.showEmpty) || !this.isEmpty);
    });
  }

  /**
   * TODO -> to mapping service
   * @param value
   * @returns
   */
  castNumberToString(value: any): string {
    if (typeof value !== 'boolean' && value !== undefined && value !== null) {
      return value.toString();
    }
    return '';
  }
}
