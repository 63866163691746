import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {KeyDataModule} from '@container/key-data/key-data.module';
import {ShellModule} from '@container/shell/shell.module';
import {PdfModule} from '@container/pdf/pdf.module';
import {DashboardModule} from '@container/dashboard/dashboard.module';
import {EditGdprModule} from '@container/edit-gdpr/edit-gdpr.module';
import {HistoricalReadoutModule} from '@container/historical-readout/historical-readout.module';
import {UiModule} from '@ui/ui.module';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DecimalPipe} from '@angular/common';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CustomHttpInterceptor} from "@utils/interceptors/custom-http-interceptor";
import { DefaultSettingsConstants } from './utils/constants/default-settings.constants';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    KeyDataModule,
    UiModule,
    ShellModule,
    KeyDataModule,
    DashboardModule,
    HistoricalReadoutModule,
    EditGdprModule,
    HttpClientModule,
    AngularEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient): TranslateHttpLoader => (new TranslateHttpLoader(http, './assets/i18n/')),
        deps: [HttpClient]
      }
    }),
    PdfModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [DecimalPipe, DefaultSettingsConstants, {provide: 'Window', useValue: window}, {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomHttpInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
