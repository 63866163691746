<!-- modal window -->
<groupui-modal id="footerModal" background="dark" close-button="true" disable-backdrop-close="true" max-width="unset" margin="50px 25px"
               (keydown)="iterateThrewElements($event)" padding="20px 25px" [ngClass]="{'pdfViewer' : selectedItem.displayMode === 'pdf'}">
  <!-- check the display mode od the modal window -->
  <div *ngIf="displayText; else viewPDF">
    <!-- modal window with the display mode text -->
    <div id="displayText"></div>
  </div>
  <!-- is needed to limit the navigation with the keyboard to the modal window when this is opened-->
  <span class="focusguardfooter" id="focusguardfooter" tabindex="0"></span>
</groupui-modal>

<!-- Footer container  -->
<div class='footer-container'>
  <div class="footer-menu">
    <div class="footer-menu-item" *ngFor="let footerItem of footerItems">
      <groupui-button variant="tertiary" size="m" (click)="toggleModalWindow(true, footerItem)">
        {{'shell.footer.' + footerItem | translate }}
      </groupui-button>
    </div>
  </div>
  <div class="footer-logo">
    <img src="assets/logos/vwag_logo.svg" alt="" />
  </div>
</div>

<!-- modal window with the display mode pdf -->
<ng-template #viewPDF>
  <groupui-headline>{{ 'shell.footer.'+ selectedItem.topic | translate }}</groupui-headline>
  <ngx-extended-pdf-viewer class="print-view" [src]="modalContent" [useBrowserLocale]="true" [showDownloadButton]="true"
    [showOpenFileButton]="false">
  </ngx-extended-pdf-viewer>
</ng-template>
