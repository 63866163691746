import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SessionStorageService } from '../session-storage/session-storage.service';

/**
 * This service handles the logout of the user.
 */
@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  /**
   * Creates an instance of LogoutService.
   *
   * @param http methods to perform HTTP requests
   * @param window allows communication with the browser window
   * @param sessionStorageService handles the session storage communication
   */
  constructor(
    @Inject('Window') public window: Window,
    public sessionStorageService: SessionStorageService,
    private http: HttpClient
  ) {}

  /**
   * Calls the Logout Enpoint to invalide the uuid, clear cookies/session storage
   * and redirects to invalid session page.
   */
  public logoutUser(): void {
    let options = {};

    this.http
      .post('/da/logout', null, options)
      .toPromise()
      .then(
        (res) => {
          // clear the session storage and cookies.
          this.sessionStorageService.removeAllItems();
          // redirect to invalid user session page.
          this.redirectToInvalidSessionPage();
        },
        (err: HttpErrorResponse) => {
          switch (err.status) {
            case 401: {
              this.sessionStorageService.removeAllItems();
              this.redirectToInvalidSessionPage();
              break;
            }
            // If the application is started locally then 404 needs to be commented out so that the logout
            // can be completed.
            case 404: {
              this.sessionStorageService.removeAllItems();
              this.redirectToInvalidSessionPage();
              break;
            }
            case 500: {
              console.log('Log out failed');
              break;
            }

            default: {
              break;
            }
          }
        }
      );
  }

  /**
   * Redirects to invalid user session page.
   */
  redirectToInvalidSessionPage(): void {
    window.location.href = window.location.href.replace(
      window.location.pathname,
      '/invalid-session/invalid-session.html?reason=logout'
    );
    // Also for the redirect to work locally the above redirect should be commented and the one below should be used.
    //window.location.href = 'https://dev3.groupservicekey.de/invalid-session/invalid-session.html?reason=logout'
  }

}
