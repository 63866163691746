import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@ui/ui.module';
import { UtilsModule } from '@utils/utils.module';
import { KeyDataComponent } from './key-data.component';
import { VehicleDataComponent } from './components/vehicle-data/vehicle-data.component';
import { ColorInformationComponent } from './components/color-information/color-information.component';
import { DistancesComponent } from './components/distances/distances.component';
import { FluidLevelsComponent } from './components/fluid-levels/fluid-levels.component';
import { WarningsComponent } from './components/warnings/warnings.component';
import { BatteryInformationComponent } from './components/battery-information/battery-information.component';
import { DiagnosticTroubleCodesComponent } from './components/diagnostic-trouble-code/diagnostic-trouble-code.component';
import { EnergyConsumerHintsComponent } from './components/energy-consumer-hints/energy-consumer-hints.component';
import { EmissionComponent } from './components/emission/emission.component';

/**
 * This module contains all necessary components for the key data page
 */
@NgModule({
  declarations: [
    KeyDataComponent,
    VehicleDataComponent,
    ColorInformationComponent,
    DistancesComponent,
    FluidLevelsComponent,
    WarningsComponent,
    BatteryInformationComponent,
    DiagnosticTroubleCodesComponent,
    EnergyConsumerHintsComponent,
    EmissionComponent,
  ],
  providers: [DatePipe],
  imports: [
    CommonModule,
    GroupUiModule,
    UiModule,
    UtilsModule,
    TranslateModule,
  ],
  exports: [KeyDataComponent],
})
export class KeyDataModule {}
