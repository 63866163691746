<app-icon-card [icon]="iconPath" [addInfoText]="addInfoText" *ngIf="displayData$ | async">
  <groupui-grid-row>
    <!-- interior color -->
    <groupui-grid-col l="7" m="12" s="12" class="unsetPaddingRight">
      <!-- title -->
      <groupui-grid-row>
        <groupui-grid-col class="value fieldName">{{mapService.mapFieldIdToPath(vehicleColor?.interior?.fieldId,
          translationPath) | translate |
          placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- color code -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(vehicleColor?.interior?.colorCode?.fieldId, translationPath)"
        [value]="vehicleColor?.interior?.colorCode?.value">
      </app-description-value-cell>
      <!-- description -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(vehicleColor?.interior?.description?.fieldId, translationPath)"
        [value]="vehicleColor?.interior?.description?.value">
      </app-description-value-cell>
      <!-- paint number -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(vehicleColor?.interior?.paintNumber?.fieldId, translationPath)"
        [value]="vehicleColor?.interior?.paintNumber?.value">
      </app-description-value-cell>
    </groupui-grid-col>

    <!-- whitespace -->
    <groupui-grid-col class="value hideL">
      &nbsp;
    </groupui-grid-col>

    <!-- exterior color -->
    <groupui-grid-col l="5" m="12" s="12" class="unsetPaddingRight">
      <!-- title -->
      <groupui-grid-row>
        <groupui-grid-col class="value fieldName">
          {{mapService.mapFieldIdToPath(vehicleColor?.exterior?.fieldId, translationPath) | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- color code -->
      <groupui-grid-row>
        <groupui-grid-col m="6" s="6" class="fieldName hideL">
          {{mapService.mapFieldIdToPath(vehicleColor?.exterior?.colorCode?.fieldId, translationPath) }}
        </groupui-grid-col>
        <groupui-grid-col l="12" m="6" s="6" class="value">
          {{vehicleColor?.exterior?.colorCode?.value | placeholder }}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- description -->
      <groupui-grid-row>
        <groupui-grid-col m="6" s="6" class="fieldName hideL">
          {{mapService.mapFieldIdToPath(vehicleColor?.exterior?.description?.fieldId, translationPath) }}
        </groupui-grid-col>
        <groupui-grid-col l="12" m="6" s="6" class="value">
          {{vehicleColor?.exterior?.description?.value | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
      <!-- paint number -->
      <groupui-grid-row>
        <groupui-grid-col m="6" s="6" class="fieldName hideL">
          {{mapService.mapFieldIdToPath(vehicleColor?.exterior?.paintNumber?.fieldId, translationPath) }}
        </groupui-grid-col>
        <groupui-grid-col l="12" m="6" s="6" class="value">
          {{vehicleColor?.exterior?.paintNumber?.value | placeholder}}
        </groupui-grid-col>
      </groupui-grid-row>
    </groupui-grid-col>

  </groupui-grid-row>
</app-icon-card>
