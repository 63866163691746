<div class="content-editor-container">
  <div id="displayMode">
    <p>
      <strong>
        {{mapService.mapCountryCodeToDisplayCountryAndCountryCode(content?.language, '-')}} |
        {{'edit-gdpr.display-mode.title' | translate}}:
      </strong>
    </p>
    <groupui-radio-group severity="none" tabindex="-1">
      <groupui-radio-button [checked]="textActive" severity="none" (click)="isTextActive('TEXT')">
        {{'edit-gdpr.display-mode.text' | translate}}
      </groupui-radio-button>
      <groupui-radio-button [checked]="!textActive" (click)="isTextActive('PDF')">
        {{'edit-gdpr.display-mode.pdf' | translate}}
      </groupui-radio-button>
    </groupui-radio-group>
  </div>

  <div *ngIf="textActive">
    <angular-editor id="contentArea" tabindex="-1" [(ngModel)]="text" (ngModelChange)="checkValidContentToSave()"
      [config]="textEditorConfig">
    </angular-editor>
  </div>

  <div *ngIf="!textActive">
    <div class="file-upload">
      <groupui-grid-row class="file-upload-row">
        <groupui-grid-col l="4" m="3" s="3">
          <groupui-button variant="secondary" (click)="fileUpload.click()">
            {{'edit-gdpr.file-upload.upload-button' | translate}}
          </groupui-button>
          <input type="file" class="file-input fileControlInput" accept=".pdf" multiple="false"
            (change)="onFileSelected($event)" #fileUpload>
        </groupui-grid-col>

        <groupui-grid-col l="8" m="9" s="9">
          <groupui-input theme="vwag" value="{{fileName}}" disabled="true">
          </groupui-input>
        </groupui-grid-col>
      </groupui-grid-row>
      <groupui-grid-row class="file-upload-row">
        <p *ngIf="fileToBig">{{'edit-gdpr.file-upload.wrong-file-size-messages' | translate}}</p>
        <p *ngIf="fileWrongFormat">{{'edit-gdpr.file-upload.wrong-file-format-messages' | translate}}</p>
      </groupui-grid-row>
      <div class="backdrop-element" *ngIf="gdprService.progress$ | async"></div>
      <groupui-progress-circle *ngIf="(gdprService.progress$ | async) as progress" displayed="true" [progress]="progress" background="transparent" severity="none" variant="primary"></groupui-progress-circle>
    </div>
  </div>

  <groupui-button-group  id="actionButtons" disabled="false" inverted="false" variant="button">
    <groupui-button tabindex="-1" (click)="updateChanges('discardChanges')" variant="secondary">
      {{'edit-gdpr.buttons.discardChanges' | translate}}
    </groupui-button>
    <groupui-button [disabled]="disableSaveButton" (click)="updateChanges('saveChanges')" variant='primary'>
      {{'edit-gdpr.buttons.save' | translate}}
    </groupui-button>
  </groupui-button-group>

</div>
